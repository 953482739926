import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import CtaDisclosure from "@tightrope/ctadisclosure"
import RedbrickBM from "@tightrope/redbmimic"

const mimrbData = import("./data/mimrbData.json");

const rbmimBrowsers = ['edge','chrome','other','ie','firefox'];

export default function prdbest() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background: #666;
      background-size: cover;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }

    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
    }

    .trbm-module--trbm .trbm-module--bullets {
      padding: 0 0 0 85px;
    }

    .redbmimic-module--rboverlay {
      background-color: #666;
    }

    .redbmimic-module--edBtn {
      background: #F5883D!important;
      font-weight: bold;
    }

    .redbmimic-module--rboverlay h1 {
      font-size: 34px;
      text-align: center;
      color: #000;
      margin: 0px 0px 50px;
    }

    .redbmimic-module--imgdiv {
      border: 1px solid #f1f1f1;
    }

    `}
    </style>
    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <RedbrickBM data={mimrbData} browsers={rbmimBrowsers}><CtaDisclosure data={mimrbData}></CtaDisclosure>
    </RedbrickBM>
      </section>
    </HomepageLayout>
  )
}
